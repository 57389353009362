import React from "react"
import { StoryGroup } from "components"

const LinkMap = {}

const stories = [
  {
    title: "CodeEditor Samples",
    postId: "27cebf3da4bb",
  },
  {
    title: "GitHub Repo Explorer Samples",
    postId: "152d524285e5",
  },
  {
    title: "JsonViewer Samples",
    postId: "a28ebb3b65a5",
  },
  {
    title: "LogViewer Samples",
    postId: "8c2540977041",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Development Samples",
  path: "react-development-samples",
}

const ReactDevelopmentSamples = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactDevelopmentSamples
